<template>
  <div class="service-picker-view">
    <h2>Pick a service</h2>
    <ServicePicker
      :ifShowMainField="false"
      :ifForceOpen="true"
      :optionsViewWidth="500" />

    <button
      type="button"
      class="logout"
      @click="this.$store.dispatch('authentication/logout')">
      Logout
    </button>
  </div>
</template>

<script setup>
  import ServicePicker from "@/components/ServicePicker/ServicePicker";
</script>

<style lang="scss">
  .service-picker-view {
    padding-top: 5rem;
    @include flex($dir: column);

    #service-picker {
      > .options {
        bottom: unset !important;
        left: calc(50% - 250px) !important;
        position: relative;
        top: -50px;
        box-shadow: none;
      }
    }

    .logout {
      border: 0;
      text-decoration: underline;
    }
  }
</style>
